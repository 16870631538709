<template>
    <a :href="checkoutPortalUrl" class="button button--bordered" :disabled="loader">
        <span class="button__loader" v-if="loader"></span>
        
        <img src="@/assets/icons/vuesax/linear/send-sqaure-2.svg" width="20" height="20" alt="" v-else>

        <span v-if="loader">Getting Subscription</span>
        <span v-else>Manage Subscription</span>
    </a>
</template>

<script setup>
import { subscriptionsServices } from '../Services/SubscriptionsServices.js'
import { ref, onMounted } from 'vue'

const { getCheckoutPortalUrlService } = subscriptionsServices()
const checkoutPortalUrl = ref(null)
const loader = ref(true)

onMounted(async () => {
    // * Get checkout portal url
    getCheckoutPortalUrl()
})

async function getCheckoutPortalUrl() {
    setTimeout(async () => {
        const response = await getCheckoutPortalUrlService()

        if (response.hasErrors) return console.error(`Error getting checkout portal url: ${response.message}`)

        checkoutPortalUrl.value = response.data

        loader.value = false
    }, 4000)
}
</script>